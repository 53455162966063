import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { instanceToInstance } from 'class-transformer';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Company } from '../../../models/company.model';
import { FileManagerFile } from '../../../models/file-manager-file.model';
import { ListNamesPipe } from '../../../pipes/list-names.pipe';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { FilePreviewComponent } from '../../../ui/components/file-preview/file-preview.component';
import { multiSort } from '../../../utilities/array';
import { checkAcceptFile } from '../../../utilities/file';
import { FileManagerService } from '../../services/file-manager.service';

@Component({
  selector: 'ag-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileManagerComponent),
    multi: true
  }]
})
export class FileManagerComponent implements ControlValueAccessor, OnInit, OnDestroy {

  /** Default files drop area. */
  @ViewChild('defaultDrop', { static: true }) private readonly defaultDrop: ElementRef;
  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;
  @ViewChild('fileMenu', { static: false }) private readonly fileMenu: PopoverDirective;

  @Input() public company: Company;
  @Input() public label: string;
  @Input() public type: string;
  /** [[Company]] IDs with which you want to share the files. */
  @Input() private shared: number[];
  @Input('due-date') public dueDate: Date;
  /** Maximum number of selectable files. */
  @Input('max-files') public maxFiles: number = 1;
  /**
   * https://agreemarket.atlassian.net/wiki/spaces/DEV/pages/90112001/Web+application+UI#Archivos
   */
  @Input('max-size') public maxSize: number = 5242880;
  /**
   * Specify what file types the user can attach.
   *
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  @Input() public accept: string = "";
  @Input() public autocomplete: "on" | "off" = "on";

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  @Input('disabled') private set isDisabled(d: boolean) {
    this._isDisabled = d;
    this.loadData();
  };
  /** Selector to set the files drop area. */
  @Input('drop-area') public dropArea: string;

  /** Triggered once Files have been uploaded. */
  @Output() private readonly uploaded: EventEmitter<File[]> = new EventEmitter();

  public get disabled(): boolean {
    return Boolean(this._isDisabled || this.fetching || this.filesUploading != undefined || this.files == undefined);
  }

  public files: FileManagerFile[];
  public filesUploading: File[];
  public isDragging: boolean;
  public selected: FileManagerFile[];
  public UUID: string;

  private _isDisabled: boolean;
  private fetching: boolean;
  private inited: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private listNamesPipe: ListNamesPipe,
    private fileManagerService: FileManagerService,
    private dataDogLoggerService: DataDogLoggerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.UUID = 'fm-' + uuidv4();
  }

  ngOnInit(): void {
    this.inited = true;
    // Setup drag and drop
    let da: Element = this.defaultDrop.nativeElement;

    if (this.dropArea) {
      const db = this.document.querySelector(this.dropArea);
      if (db) da = db;
    }

    this.setupDrop(da);

    this.shared = this.shared?.filter(companyId => companyId !== this.company.id) || [];

    this.loadData();
  }

  private loadData(): void {
    if (!this.files && !this.fetching && !this._isDisabled && this.inited) {
      this.fetching = true;
      const notShared: boolean = this.shared.length === 0;

      this.subscriptions.push(this.fileManagerService.get(this.company.id, {
        type: this.type,
        shared: this.shared,
        private: notShared
      }).subscribe({
        next: response => {
          this.files = multiSort(response.body, '-createdAt');

          if (this.files.length > 0 && this.autocomplete === 'on') {
            this.setSelected(this.files.slice(0, this.maxFiles));
          }

          this.fetching = false;
        },
        error: error => {
          // Non fatal error
          this.dataDogLoggerService.warn(error.message, error.error);
          this.files = [];
          this.fetching = false;
        }
      }));
    }
  }

  private setupDrop(el: Element): void {
    const dragger = (e: DragEvent) => {
      if (!this.disabled) {
        e.stopPropagation();
        e.preventDefault();
        this.defaultDrop.nativeElement.className = 'is-dragging';
        this.isDragging = true;
      }
    };
    const removeDrag = () => {
      this.defaultDrop.nativeElement.className = '';
      this.isDragging = false;
    };

    el.addEventListener("dragenter", dragger, false);
    el.addEventListener("dragover", dragger, false);
    el.addEventListener("drop", (e: DragEvent) => {
      if (!this.disabled) {
        dragger(e);

        // Triggered by drop
        this.addFiles(e.dataTransfer.files);

        removeDrag();
      }
    }, false);
    el.addEventListener("dragleave", removeDrag, false);
    el.addEventListener("dragend", removeDrag, false);
  }

  public addFiles(files: FileList): void {
    // Triggered by input component
    let filesSoFar = (this.selected ? this.selected.length : 0) + (this.filesUploading ? this.filesUploading.length : 0);
    if (filesSoFar + files.length > this.maxFiles) {
      this.showToastMessage(this.translateService.instant('ERROR_LIST.MAX_FILES', { max: this.maxFiles }));
    } else {
      let rejectedFiles: File[] = [];
      let newFiles: File[] = [];

      for (let i = 0, l = files.length; i < l; i++) {
        const file = files[i];

        if ((file.size || 0) <= this.maxSize &&
          checkAcceptFile(file, this.accept)) {
          newFiles.push(file);
        } else {
          rejectedFiles.push(file);
        }
      }

      if (rejectedFiles.length) {
        this.showToastMessage(this.translateService.instant('FILE_INPUT.REJECTED', { files: this.listNamesPipe.transform(rejectedFiles) }));
      }

      this.upload(newFiles);
    }
  }

  private upload(files: File[]): void {
    if (files.length) {
      this.filesUploading = files;

      this.subscriptions.push(this.fileManagerService.upload(this.company.id, this.type, files, this.label, this.dueDate).subscribe(response => {
        this.uploaded.emit(files);
        this.share(response);
        this.files = this.files.concat(response);

        this.setSelected([...(this.selected || []), ...response]);
        this.filesUploading = undefined;
      }));
    }
  }

  private share(files: FileManagerFile[]): void {
    if (this.shared.length > 0) {
      files.forEach(file => {
        this.fileManagerService.share(this.company.id, file, this.shared).subscribe();
      });
    }
  }

  public remove(fileId: string): void {
    this.setSelected(this.selected.filter(file => file.id !== fileId));
  }

  public add(file: FileManagerFile): void {
    this.fileMenu.hide();
    this.setSelected([...(this.selected || []), file]);
  }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(file: FileManagerFile): void {
    if (!this.disabled) this.filePreviewer.preview(this.fileManagerService.getFile(this.company.id, file.id), file.name + file.extension, file.size);
  }

  public delete(file: FileManagerFile): void {
    this.subscriptions.push(this.fileManagerService.delete(this.company.id, file).subscribe(response => {
      this.files = this.files.filter(f => file.id !== f.id);
    }));
  }

  private setSelected(from: FileManagerFile[] = []): void {
    let newValue: string[] = [];
    this.selected = instanceToInstance(from);
    this.selected.forEach(file => {
      newValue.push(file.id);
    });

    this._value = newValue;
    this.propagateChange(this.value);
  }

  public openDialog(): void {
    this.document.getElementById(this.UUID).click();
  }

  private showToastMessage(message: string): void {
    this.toastrService.warning(message, undefined, {
      closeButton: true
    });
  }

  // ngModel
  public _value: string[];

  @Input() set value(value: string[]) {
    // Reset
    if (value?.length === 0) this.setSelected([]);
    else {
      if (value?.length && this.files) {
        const selected = [];
        value.forEach(id => {
          const file = this.files.find(file => file.id == id);
          if (file) selected.push(file);
        });
        this.setSelected(selected);
      } else this._value = value;
    }
  }
  get value(): string[] {
    return this._value;
  }
  private propagateChange = (_: any) => { };

  writeValue(value: string[]) {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}