<table-detail *ngIf="note?.notes"
[header]="'NOTES.FOR'|translate: { company: forCompany.name }"
[collapsible]="true"
[expanded]="expanded">
  <table class="app-data">
    <tbody>
      <tr *ngFor="let attr of note.notes|keyvalue">
        <th class="sap"><b>{{ attr.value.slug }}</b></th>
        <td [ngSwitch]="attr.value.type">
          <span *ngSwitchCase="'BOOLEAN'"
          class="material-symbols-rounded"
          [class.text-success]="attr.value.value"
          [class.text-danger]="!attr.value.value">{{ attr.value.value ? 'done' : 'close' }}</span>
          <samp *ngSwitchCase="'NUMBER'"
          [class.text-danger]="attr.value.value < 0"
          [class.text-muted]="attr.value.value === 0">{{ attr.value.value | number:'1.0-2':currentLang }}</samp>
          <span *ngSwitchDefault>{{ attr.value.value }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</table-detail>