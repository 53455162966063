import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Company } from '../../../models/company.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { SubscriptionManager } from '../../../utilities/subscription-manager';
import { CompanyNote } from '../../models/company-note.model';
import { NotesService } from '../../services/notes.service';

@Component({
  selector: 'ag-company-notes',
  templateUrl: './company-notes.component.html',
  styleUrls: ['./company-notes.component.scss']
})
export class CompanyNotesComponent extends SubscriptionManager implements OnInit, OnDestroy {

  @Input() private company: Company;
  @Input('for') private forCompany: Company;
  @Input() public expanded: boolean = true;

  /** The language currently used. */
  public currentLang: string;
  public note: CompanyNote;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  constructor(
    private notesService: NotesService,
    private translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
    
    this.loadData();
  }

  private loadData(): void {
    this.processing = true;

    this.subscriptions.push(this.notesService.getById(this.company.id, this.forCompany.fiscal_id.value).subscribe({
      next: note => {
        this.note = note;
        this.processing = false;
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
        this.processing = false;
      }
    }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
