import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, concatMap, from } from 'rxjs';

import { instanceToInstance } from 'class-transformer';
import { Account } from '../../../../../auth/models/account.model';
import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company } from '../../../../../models/company.model';
import { Slot, StopSynch } from '../../../../../models/slot.model';
import { SlotsBatch } from '../../../../../models/slots-batch.model';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { SlotService } from '../../../../../services/slot.service';
import { startOfDay } from '../../../../../utilities/date';

/**
 * Shows a table of [[Slot|Slots]]. It includes the possibility of withdrawing,
 * reviewing the history and generating [[Waybill|Waybills]] for each one.
 * 
 * ## Usage
 * ``` html
 * <ag-batch-slots-table
 * [company]="company"
 * [batch]="batch"
 * [slots]="batch?.slots"></ag-batch-slots-table> 
 * ```
 * 
 * ### Related UI components:
 * - [[WaybillModalComponent]]
 * - [[SlotsComponent]]
 * - [[ViewBatchComponent]]
 */
@Component({
  selector: 'ag-batch-slots-table',
  templateUrl: './batch-slots-table.component.html',
  styleUrls: ['./batch-slots-table.component.scss']
})
export class BatchSlotsTableComponent implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() set batch(batch: SlotsBatch) {
    if (batch) {
      this._batch = batch;

      this.today = startOfDay(this.currentDate.get());
      this.returnable = this.canReturn();
      this.deleteable = this.canDelete();
      this.expired = this.today.getTime() > batch.date.getTime();
      this.synchronizable = this.company.stop_key != undefined &&
        (this.today.getTime() - (30 * 24 * 60 * 60 * 1000)) <= batch.date.getTime() &&
        this.batch.destination.company.fiscal_id.value != this.company.fiscal_id.value;
    }
  };
  get batch(): SlotsBatch {
    return this._batch;
  }

  @Input() public slots: Slot[];
  /** It specifies that the component should be disabled. */
  @Input() public disabled: boolean;
  @Input() public readonly: boolean;
  @Input() public canWithdraw: boolean = false;

  @Output() private readonly onWithdrawal = new EventEmitter();

  public get almostOneToDelete(): boolean {
    return this.slots.some(slot => !slot.holder);
  }
  /** Whether the specified [[SlotsBatch|Batch]] is expired or not. */
  public expired: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** @ignore */
  public slotHistory: any[];
  /** Current [[User]]. */
  public user: User;
  /** 
   * Withdrawal reason.
   */
  public withdrawalReason: string;
  /** [[Slot|Slots]] to be withdrawn. */
  public withdrawSelection: Slot[] = [];
  /** Whether the [[SlotsBatch|batch]] can be returnable or not. */
  public returnable: boolean;
  /** Whether the [[SlotsBatch|batch]] can be deleteable or not. */
  public deleteable: boolean;

  public synchronizable: boolean;
  public synchronizing: boolean;

  private _batch: SlotsBatch;
  private subscriptions: Subscription[] = [];
  private today: Date;

  /** @ignore */
  constructor(
    private currentDate: CurrentDateService,
    private loginService: LoginService,
    private slotService: SlotService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  /** Withdraws the selected [[Slot|Slots]]. */
  public withdrawSelectedSlots(): void {
    if (this.withdrawSelection.length) {
      this.processing = true;
      this.subscriptions.push(this.withdrawSlots(this.withdrawSelection).subscribe(response => {
        this.onWithdrawal.emit();
        this.processing = false;
      }));
    }
  }

  private canReturn(): boolean {
    return this.batch.company &&
      this.batch.company.id === this.company.id && // Is the owner
      this.batch.allocator && // Was assigned
      this.batch.allocator.id !== this.company.id && // It wasn't assigned by my Company
      this.batch.status.id === 2 && // It's accepted
      !this.readonly &&
      !this.expired;
  }

  private canDelete(): boolean {
    return (!this.batch.allocator || this.batch.allocator?.id === this.company.id) && !this.readonly
  }

  public slotsCanBeDeleted(slots: Slot[]): Slot[] {
    if (!slots) return undefined;

    return slots.filter(slot => !slot.holder);
  }

  private withdrawSlots(slots: Slot[], sourceBatch: SlotsBatch = this.batch): Observable<any> {
    let batch = new SlotsBatch();

    batch.status = {
      id: 4,
      reason: this.withdrawalReason
    };

    batch.allocator = sourceBatch.allocator;
    batch.company = sourceBatch.company;
    batch.negotiation = sourceBatch.negotiation;
    batch.unassigned = slots;
    batch.date = sourceBatch.date;
    batch.destination = sourceBatch.destination;
    batch.product = sourceBatch.product;
    batch.recipient = sourceBatch.recipient;

    return this.updateBatch(sourceBatch.id, batch.status, batch);
  }

  private updateBatch(batch_id: number, status: any, rejectedBatch?: SlotsBatch): Observable<any> {
    return this.slotService.update(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], batch_id, status, rejectedBatch);
  }

  private filterAccount(account: Account): any {
    if (account.company_id === this.company.id && account.user_id === this.user.id) {
      return account;
    }
  }

  /**
   * Given an [[Slot|Slots]] array, checks if the specified [[Slot]] is one of
   * them.
   */
  private isSlotHere(arr: Slot[], slot: Slot): boolean {
    return !!arr.find(s => s.id === slot.id);
  }

  /**
   * Given an [[SlotsBatch|batches]] array, finds the one that contains the
   * specified [[Slot]].
   */
  private findBatch(batches: SlotsBatch[], slot: Slot): SlotsBatch {
    return batches.find((sb: SlotsBatch) => this.isSlotHere(sb.slots, slot));
  }

  private updateSlotsAccount(slots: Slot[]): Slot[] {
    return this.slots.filter(function (originalSlot) {
      return !slots.some(function (slotToCompare) {
        return originalSlot.id === slotToCompare.id;
      });
    });
  }

  /** Returns a [[Slot]] to the [[SlotsBatch.allocator|allocator Company]]. */
  public returnSlots(slotsSelected: Slot[]): Subscription {
    this.processing = true;

    let slotsSelectedAssigned: Slot[] = slotsSelected.filter(slot => slot.holder);

    // If there are no slots assigned to any company, I return them directly
    if (!slotsSelectedAssigned.length) {
      return this.returnBatch(slotsSelected).subscribe(() => {
        this.slots = this.updateSlotsAccount(slotsSelected);
        this.withdrawSelection = [];
        this.processing = false;
      })
    }

    // agroup all the lots to which the batch belong
    let batchsOfSlots: { batch: SlotsBatch, slotsToWithdraw: Slot[] }[] = [{
      batch: this.batch.assignments[0],
      slotsToWithdraw: []
    }];

    slotsSelectedAssigned.forEach(slotAssigned => {
      let batchFinded = this.findBatch(this.batch.assignments, slotAssigned);

      // scroll through and save the batches depending on whether or not they already exist in the array.
      batchsOfSlots.forEach((batchObj) => {
        if (batchObj.batch.id === batchFinded.id) {
          return batchObj.slotsToWithdraw.push(slotAssigned)
        }

        batchsOfSlots.push({ batch: batchFinded, slotsToWithdraw: [slotAssigned] })
      })
    })

    // withdraw the slots by batch rounds and at the end I return all the batches.
    from(batchsOfSlots).pipe(
      concatMap(batch => this.withdrawSlots(batch.slotsToWithdraw, batch.batch))
    ).subscribe(() => {
      this.returnBatch(slotsSelected).subscribe(() => {
        this.slots = this.updateSlotsAccount(slotsSelected);
        this.withdrawSelection = [];
        this.processing = false;
      })
    })
  }

  private returnBatch(slots: Slot[]): Observable<any> {
    let batch = new SlotsBatch();

    batch.parent_id = this.batch.parent_id;
    batch.allocator = this.batch.allocator;
    batch.company = this.batch.company;
    batch.status = { id: 5 };
    batch.negotiation = this.batch.negotiation;
    batch.unassigned = slots;
    batch.date = this.batch.date;
    batch.destination = this.batch.destination;
    batch.product = this.batch.product;
    batch.recipient = this.batch.recipient;

    return this.updateBatch(this.batch.id, batch.status, batch);
  }

  public deleteSlots(slots: Slot[]): void {
    this.processing = true;
    const ids: number[] = slots.map(a => a.id);
    this.subscriptions.push(this.slotService.deleteSlots(this.company.id, ids).subscribe(response => {
      this.slots = this.slots.filter(slot => {
        return !ids.includes(slot.id);
      });
      this.withdrawSelection = [];
      this.processing = false;
    }));
  }

  /**
   * Synchronizes the table data by sending the current slot references and company's fiscal ID (CUIT) 
   * to the backend for batch processing. Sets `synchronizing` flag during sync.
   */
  public synchTable(): void {
    const cuit = this.batch.destination.company.fiscal_id.value;

    // Create payload with the required data (slot references and CUIT)
    const payload = this.slots
      .filter(slot => !slot.unloaded || slot.stop?.status.id != 3)
      .map(slot => ({ reference: slot.reference, cuit, date: this.batch.date }));

    if (payload.length > 0) {
      this.synchronizing = true;

      // Push subscription to the list, which will synchronize multiple slots
      this.subscriptions.push(
        this.slotService.STOPsyncMultiple(this.company.id, payload).subscribe({
          next: response => {
            this.synchronizing = false;
            this.renderSynchResponse(response);
          },
          error: error => {
            // Non fatal error
            this.dataDogLoggerService.warn(error.message, error.error);
            this.toastrService.warning(this.translateService.instant('NOT_FOUND.TITLE'), undefined, {
              closeButton: true
            });
            this.synchronizing = false;
          }
        })
      );
    }
  }

  /**
   * Updates the local slots based on the synchronization response statuses.
   * 
   * @param {StopSynch} response - The response containing the synchronization statuses for slots.
   */
  private renderSynchResponse(response: StopSynch): void {
    // Verify if there are statuses to process
    const statuses = response.statuses || [];
    if (statuses.length === 0) return;

    const copy = instanceToInstance(this.slots);

    statuses.forEach(status => {
      const reference = status.idCupoTerminal;
      // Find the slot with a matching reference
      const slot = copy.find(s => s.reference === reference);

      if (slot && status.idCupoEstado) {
        // Update the slot's stop information with new status details
        slot.stop = {
          ...slot.stop,
          status: {
            id: status.idCupoEstado,
            name: status.estadoTurno ?? ''
          },
          updated_at: new Date(),
          source: status
        };

        if (status.ctg) slot.ctg = status.ctg;
        // } else {
        // 'Slot with reference ${reference} not found or incomplete status information.`
      }
    });

    // This hack force render
    this.slots = copy;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
