<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <table class="table table-condensed table-hover" sortable #tableSortable="tableSortable" [variableInUrl]="!predefinedFilters"
      AgMultiselection [(ngModel)]="selected">
      <thead>
        <tr class="tr-align-center">
          <th class="sap micro" *ngIf="enableSelection &&
          counterparts?.length > 0">
            <input type="checkbox" AgSelectall
            id="select-all-toggle"
            class="show-onhover no-margin"
            tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
            container="body" [adaptivePosition]="false">
          </th>
          <th class="hidden-xs sap"></th>
          <th class="hidden-xs hidden-sm" *ngIf="company?.market.configuration?.company.address.country.enabled"></th>
          <th sortBy="name">{{ 'GLOBAL.COMPANY'|translate }}
            <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            [filters]="filters"
            key="filters[name]"
            alignment="left"
            container=".app-content"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <th class="sap"></th>
          <!-- <th class="hidden-xs hidden-sm sap"></th> -->
          <th class="hidden-xs hidden-sm" sortBy="fiscal_id.value"
          *ngIf="company?.market.configuration.company.fiscal_id">
            {{ company?.market.configuration.company.fiscal_id.label }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            [filters]="filters"
            key="filters[fiscal_id.value]"
            alignment="left"
            container=".app-content"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="hidden-xs hidden-sm">{{ 'GLOBAL.MAIN_ACTIVITY'|translate }}</th>
          <th></th>
          <th class="hidden-xs sap" sortBy="verification_date">
            <abbr title tooltip="{{ 'COUNTERPARTIES.VERIFICATION_DATE'|translate }}" [adaptivePosition]="false">
              {{ 'GLOBAL.DATE'|translate }}
            </abbr>
            <column-filter
            *ngIf="!predefinedFilters"
            type="date"
            [filters]="filters"
            key="filters[verification_date]"
            container=".app-content"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <th class="text-center network" sortBy="approved"
          *ngIf="company?.hasModule('market')">
            {{ 'COUNTERPARTIES.APPROVED'|translate }}
            <column-filter
            *ngIf="!predefinedFilters"
            type="boolean"
            [filters]="filters"
            key="filters[approved]"
            container=".app-content"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
          <th class="network text-center sb tb" sortBy="network_relation.status.id"
          *ngIf="company?.hasModule('my-network')">{{
            'COUNTERPARTIES.MY_NETWORK'|translate }}
            <column-filter
            *ngIf="!predefinedFilters"
            type="custom"
            [options]="[
              { name: 'COUNTERPARTIES.NETWORK.PENDING', value: '1' },
              { name: 'COUNTERPARTIES.MY_NETWORK', value: '2' }
            ]"
            [filters]="filters"
            key="filters[network_relation.status.id]"
            container=".app-content"
            (onChange)="changeFilter($event)"></column-filter>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let counterpart of counterparts; let last = last"
        class="tr-align-center td-height"
        [class.tr-disabled]="!counterpart.approved">
          <td class="sap micro"
          *ngIf="enableSelection">
            <input
            [AgCheckbox]="counterpart" type="checkbox"
            class="show-onhover no-margin">
          </td>
          <td class="hidden-xs sap">
            <company-logo [company]="counterpart"></company-logo>
          </td>
          <td class="sap hidden-xs hidden-sm" *ngIf="company?.market.configuration?.company.address.country.enabled">
            <flag [country]="counterpart.address?.country"></flag>
          </td>
          <td>
            <company-info class="hidden-xs hidden-sm" [compact]="true" [company]="counterpart"
            [commercial-zones]="true"></company-info>
            <company-info class="visible-xs visible-sm" [company]="counterpart"
            [commercial-zones]="true"></company-info>
          </td>
          <td class="sap">
            <div class="ag-label label-warning" *ngIf="counterpart.is_new">{{ 'COUNTERPARTIES.NEW'|translate }}</div>
          </td>
          <!-- <td class="sap hidden-xs hidden-sm"><certificates-button *ngIf="company.market.configuration.certificates?.enabled" [company]="counterpart"></certificates-button></td> -->
          <td class="hidden-xs hidden-sm small" *ngIf="company?.market.configuration.company.fiscal_id">
            <fiscal-id [value]="counterpart.fiscal_id"></fiscal-id>
          </td>
          <td class="hidden-xs hidden-sm">{{ counterpart.activity.label || counterpart.activity.name }}</td>
          <td>
            <span *ngFor="let zone of counterpart.commercial_zones"
            [attr.title]="zone.description"
            class="ag-label label-default">{{ zone.name }}</span>
          </td>
          <td class="sap hidden-xs small">
            <date-md [date]="counterpart.verification_date"></date-md>
          </td>
          <td *ngIf="company?.hasModule('market')" class="text-center dlb">
            <input class="ios" *ngIf="counterpart.approved; else blocked"
            [disabled]="processing" type="checkbox" id="open_{{ counterpart.id }}"
            [(ngModel)]="counterpart.approved"
            title="{{ 'COUNTERPARTIES.STATUS.REMOVE_TOOLTIP'|translate }}"
            (onConfirm)="block(counterpart)"
            [confirm-options]="{
              title: 'COUNTERPARTIES.STATUS.REMOVE_TITLE'|translate,
              body: 'COUNTERPARTIES.STATUS.REMOVE_BODY'|translate,
              action: 'COUNTERPARTIES.STATUS.YES_REMOVE'|translate
            }">
            <ng-template #blocked>
              <input class="ios" [disabled]="processing" type="checkbox" id="open_{{ counterpart.id }}"
                [(ngModel)]="counterpart.approved" (change)="unblock(counterpart)">
            </ng-template>
          </td>
          <td class="sb text-center" *ngIf="company?.hasModule('my-network')"
          [class.bb]="last"
          [class.my-network]="counterpart.network_relation?.status.id === 2 && counterpart.approved">
            <ng-container *ngIf="counterpart.network_relation; else nonetwork_relation">
              <input class="ios" *ngIf="counterpart.network_relation.status.id === 2; else pending"
              [disabled]="processing || !counterpart.approved"
              type="checkbox" id="n_{{ counterpart.id }}" checked
              title="{{ 'COUNTERPARTIES.NETWORK.REMOVE_TOOLTIP'|translate }}"
              (onConfirm)="removeFromMyNetwork(counterpart)"
              [confirm-options]="{
                title: 'COUNTERPARTIES.NETWORK.REMOVE_TITLE'|translate,
                body: 'COUNTERPARTIES.NETWORK.REMOVE_BODY'|translate,
                action: 'GLOBAL.YES_REMOVE'|translate
              }">
              <ng-template #pending>
                <ng-container *ngIf="counterpart.network_relation.to_company_id === company.id; else waiting">
                  <button type="button"
                  class="btn btn-agree btn-sm" (click)="acceptNetworkRequest(counterpart)"
                  [disabled]="processing">
                    {{ 'COUNTERPARTIES.NETWORK.ACCEPT'|translate }}
                  </button>
                </ng-container>
                <ng-template #invitedTemplate>
                  <p class="small"><b>{{ 'COUNTERPARTIES.NETWORK.MODAL.EMAILS'|translate }}</b></p>
                  <div *ngFor="let e of counterpart.invited_emails">{{ e }}</div>
                </ng-template>
                <ng-template #waiting>
                  <button type="button"
                  class="btn btn-default btn-sm"
                  (onConfirm)="removeFromMyNetwork(counterpart)"
                  [confirm-options]="{
                    title: 'COUNTERPARTIES.NETWORK.WITHDRAW_TITLE'|translate,
                    body: 'COUNTERPARTIES.NETWORK.WITHDRAW_BODY'|translate,
                    action: 'GLOBAL.YES_WITHDRAW'|translate
                  }"
                  [popover]="counterpart.invited_emails?.length ? invitedTemplate : null"
                  triggers="mouseenter:mouseleave"
                  placement="left"
                  [disabled]="processing">
                    {{ 'COUNTERPARTIES.NETWORK.CANCEL'|translate }}
                  </button>
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-template #nonetwork_relation>
              <input title="{{ 'COUNTERPARTIES.NETWORK.ADD'|translate }}" class="ios"
              [disabled]="processing || !counterpart.approved"
              type="checkbox" id="n_{{ counterpart.id }}" (change)="addToMyNetwork(counterpart)">
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="no-data" *ngIf="counterparts && counterparts.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'INVOICES.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>