import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Slot, Stop } from '../../../../../models/slot.model';

@Component({
  selector: 'ag-stop-status',
  templateUrl: './stop-status.component.html',
  styleUrls: ['./stop-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopStatusComponent {

  @Input()
  set slot(value: Slot) {
    this._slot = value;
    this.details = this.calculateDetails();
    this.class = this.calculateStopClass();
    this.hasTooltip = this.calculateTooltip();
  }

  get slot(): Slot {
    return this._slot;
  }

  public details: { label: string; date: Date }[] = [];
  public class: string = '';
  public hasTooltip: boolean = false;

  public get stop(): Stop {
    return this.slot.stop;
  }

  private _slot: Slot;

  private calculateDetails(): { label: string; date: Date }[] {
    return [
      { label: 'IMPORTER.UPDATED', date: this.stop?.updated_at },
      { label: 'SLOTS.STATUS.STOP.2', date: this.stop?.activated_at },
      { label: 'SLOTS.STATUS.STOP.5', date: this.stop?.arrived_at },
      { label: 'SLOTS.STATUS.STOP.3', date: this.stop?.unloaded_at },
    ];
  }

  private calculateStopClass(): string {
    if (this.stop) {
      const unloaded = this._slot?.unloaded;
      if (unloaded) this.stop.status.id = 3;

      switch (this.stop.status.id) {
        case 1:
          return 'label-danger';
        case 2:
          return 'label-warning';
        case 3:
          return 'label-success';
        case 5:
          return 'label-info';
      }
    }

    return 'label-default';
  }

  private calculateTooltip(): boolean {
    return !!this.stop?.updated_at ||
      !!this.stop?.activated_at ||
      !!this.stop?.arrived_at ||
      !!this.stop?.unloaded_at;
  }
}
