import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map, of } from 'rxjs';

import { JSend } from '../../models/jsend.model';
import { CacheService } from '../../services/cache.service';
import { buildFilters } from '../../utilities/filters';
import { CompanyNote } from '../models/company-note.model';
import { DataColumn } from '../modules/fintech/models/work-flow-data-field.model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  private baseUrl: string = '/:apiNotes/companies/:companyId';
  private notesUrl: string = this.baseUrl + '/notes';
  private fiscalIdUrl: string = this.notesUrl + '/:fiscalId';
  private columnsUrl: string = this.baseUrl + '/columns';
  private columnUrl: string = this.columnsUrl + '/:uuid';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;
  private readonly CACHE_MINUTES = 10;

  constructor(
    private cacheService: CacheService,
    private http: HttpClient
  ) { }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: CompanyNote[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.notesUrl
      .replace(':companyId', companyId.toString());

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<{
      notes: CompanyNote[]
    }>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(CompanyNote, response.body.data.notes), headers: response.headers };
    }));
  }

  public create(companyId: number, notes: CompanyNote[]): Observable<CompanyNote[]> {
    const url = this.notesUrl
      .replace(":companyId", companyId.toString());

    return this.http.post<JSend<{
      notes: CompanyNote[]
    }>>(url, notes).pipe(map(response => {
      return plainToInstance(CompanyNote, response.data.notes);
    }));
  }

  public edit(companyId: number, note: CompanyNote): Observable<CompanyNote> {
    const url = this.fiscalIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":fiscalId", note.fiscalId);

    return this.http.put<JSend<CompanyNote>>(url, note).pipe(map(response => {
      return plainToInstance(CompanyNote, response.data);
    }));
  }

  public delete(companyId: number, note: CompanyNote): Observable<any> {
    const url = this.fiscalIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":fiscalId", note.fiscalId);

    return this.http.delete<any>(url);
  }

  public getById(companyId: number, fiscalId: string): Observable<CompanyNote> {
    const url = this.fiscalIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":fiscalId", fiscalId);

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(plainToInstance(CompanyNote, cached));

    return this.http.get<JSend<{ note: CompanyNote }>>(url).pipe(map(response => {
      this.cacheService.set(url, response.data.note, this.CACHE_MINUTES);
      return plainToInstance(CompanyNote, response.data.note);
    }));
  }

  public getColumns(companyId: number): Observable<DataColumn[]> {
    let url = this.columnsUrl
      .replace(':companyId', companyId.toString());

    const stream = this.http.get<JSend<{ columns: DataColumn[] }>>(url);

    return stream.pipe(map(response => {
      return plainToInstance(DataColumn, response.data.columns);
    }));
  }

  public createColumns(companyId: number, columns: DataColumn[]): Observable<DataColumn[]> {
    const url = this.columnsUrl
      .replace(":companyId", companyId.toString());

    return this.http.post<JSend<{
      columns: DataColumn[]
    }>>(url, columns).pipe(map(response => {
      return plainToInstance(DataColumn, response.data.columns);
    }));
  }

  public deleteColumn(companyId: number, column: DataColumn): Observable<any> {
    const url = this.columnUrl
      .replace(":companyId", companyId.toString())
      .replace(":uuid", column.uuid);

    return this.http.delete<any>(url);
  }
}
